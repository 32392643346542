<template>
	<!-- flex_column -->
	<div class="industry_page">
		<!-- 按钮搜索区域 -->
		<div class="hangar_pages_top flex_column zui-relative">
			<div class="flex_8 flex_column zui-p-x-10">
				<div class="zui-h4" style="margin-top: 50px;">{{detail.titleName}}</div>
				<div class="zui-p-t-20" style="font-size:0.3rem;">{{$t('industryNews.sendDate')}}：{{detail.createTime}}
				</div>
			</div>
			<div class="flex_2 flex_row"></div>
			<div class="btnblock header_r padding-0 col-md-1  col-sm-2 col-xs-3">
				<div v-if="!isLogin" class="zui-h-100 zui-pointer zui-flex zui-center-center log"
					style="font-size: 12px;">{{$t('login.login')}}</div>
				<div class="btnCenter">
					<span @click="_back" v-show="!isPhone"
						class="jinBtn cursor  zui-text-white back">{{$t('common.back')}}</span>
				</div>
			</div>
		</div>
		<div class="news-content" v-html="detail.titleContent"></div>
		<div id="back" v-show="isPhone" @click="_back"
			style="z-index: 999; position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
	</div>

</template>

<script>
	import {
		mapState
	} from 'vuex';
	// 行业动态详情页面
	export default {
		data() {
			return {
				isPhone: false,
				buttonList: [{
						name: '行业新闻',
						id: '0'
					},
					{
						name: '厂商新闻',
						id: '1'
					}
				],
				curBtn: '',
				detail: {},
			};
		},
		computed: {
			...mapState(['isLogin']),
		},
		created: function() {
			if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
				this.isPhone = true
			} else {
				this.isPhone = false
			}

			// console.log(this.$route.params);
			if (this.$zui.isEmptyObj(this.$route.params)) {
				this.$router.push({
					path: '/industryNews'
				});
			}
		
			this.detail = this.$route.params.detail;

		},
		methods: {
			_back(){
				this.$router.push({name: 'industryNews',params:{id:this.detail.id}})
			
			},
			comData(e) {
				console.log('e', e);
			},
			toDetail() {
				this.$router.push('/industryNewsDetail/' + 1);
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '../assets/scss/config.scss';
	@import '../assets/scss/mixin.scss';

	.log {
		padding-right: 12px;
		// font-weight: bold;
		color: #dbb96e;
		opacity: 0;
		-webkit-user-select: none;
		cursor: default;
	}

	.btnblock {
		width: 8.333333333%;
		position: absolute;
		// height: 100px;
		right: 0;
		display: flex;
		justify-content: center;
	}

	.btnCenter {
		height: 26px;
		width: 3em;
		display: flex;
		justify-content: center;
	}

	.hangar_pages_top {
		width: 100%;
		height: 138px;
		background: url('../assets/image/15.jpg') no-repeat center;
		background-size: 100% 100%;

		div {
			color: $colorG;
			font-size: $fontJ;
			// text-align: center;
		}
	}

	.industry_page {
		padding: 0;
		margin: 0;
		background-color: #F1F4F5;
	
	.top {
			padding: 20px 0;
			background: $colorG;

			.top_left {
				position: relative;
				width: 100%;
				height: 100%;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.top_right {
				height: 100%;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				align-items: flex-start;
			}
		}

		.bottom {
			padding: 20px 0;
			background: #eeeeee;

			.bottom_left {
				position: relative;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.bottom_right {
				height: 100%;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
			}
		}
	}

	.el-row {
		width: 100%;
	}

	.el-col {
		border-radius: 4px;
	}

	.bg-purple-dark {
		// background: #99a9bf;
	}

	.bg-purple {
		// background: #d3dce6;
	}

	.bg-purple-light {
		// background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.tag {
		position: absolute;
		background-color: #000000;
		border-radius: 0 20px 20px 0;
		color: #ffffff;
		line-height: 32px;
		font-size: 14px;
		text-align: center;
		top: 8%;
		padding: 0px 10%;
		height: 32px;
		border: 1px solid #000000;
	}

	/deep/ .news-content {
		width: 60%;
		overflow: hidden;
		margin: 0 auto;
		max-width: 100%;
		// p{margin: 0 !important;}
		// padding: 20px  calc(16.66%  + 20px);
		font-family: sans-serif;
			//列表元素
		li{ display:list-item }
		ol{list-style-type: decimal }
		ol ul, ul ol,ul ul, ol ol  { margin-top: 0; margin-bottom: 0 }
		a{color: -webkit-link;text-decoration: none;}
		//标题 
		h1{ font-size: xx-large; }
		h2{ font-size: x-large; }
		h3{ font-size: large;  }
		h4{
			font-size: medium;
		}

		h5 { font-size: small;  }
		h6{ font-size: x-small;  }
		h1, h2, h3, h4,h5, h6{
			line-height: inherit;
				margin: 0;
			clear:both;
		}
		h1, h2, h3, h4,h5, h6, b,strong  { font-weight: bold;}
		img {
			max-width: 100% !important;
			height: auto;
		}
		video{
			max-width: 100% !important;
			height: auto;
		}
	
	}

	@media only screen and (max-width : 992px) {
		.btnblock {
			width: 33.3333333%;
		}
	}

	@media only screen and (max-width : 960px) {
		.news-content {
			width: 95%;
	
		}
	}

	@media only screen and (max-width : 768px) {
		.btnblock {
			width: 25%;
		}

		/deep/ .tag {
			height: 20px !important;
			line-height: 20px;
			font-size: 10px;
			padding: 0px 8%;
		}

		/deep/ .back {
			right: 4%;
			top: 5%;
			display: flex;
			align-items: center;
			justify-content: center;
			// line-height: 1;
			// // padding:0.08rem 0.23rem ;
			// font-size: 	0.30rem;
		}

		/deep/ .top_right {
			font-size: 12px !important;
		}
		/deep/ .bottom_right {
			font-size: 12px !important;
		}

		.news-content {
			width: 95%;
			padding: 10px;
		}

	
	/deep/ iframe {
			width: 100% !important;
			height: 100% !important;
		}

		/deep/ img {
			width: 100%;
			height: 100%;
		}
	}
</style>
